import { BudgetState } from "./budget-state.entity";
import { Car } from "./car.entity";
import { Company } from "./company.entity";
import { User } from "./user.entity";
import { BudgetItem } from "./budget-item.entity";
import { Workshop } from "./workshop.entity";
import { UserWorkBudget } from "./user-work-budget.entity";

export class Budget {
  static readonly DEFAULT_EXPIRATION_DAYS = 15;
  readonly id!: number;
  readonly creation_date!: Date;
  readonly description!: string;
  readonly company!: Company;
  readonly workshop!: Workshop;
  readonly car!: Car;
  readonly workshopBudgetItems!: BudgetItem[];
  readonly budgetState!: BudgetState;
  readonly createdBy!: User;
  readonly expiration_days!: number;
  readonly in_workshop!: boolean;
  readonly previous_budget_id!: number;
  readonly turn!: Date;
  readonly purchaseBudget!: number;
  readonly location!: string;
  readonly appointment_date!: Date;
  readonly usage!: number;
  readonly workshop_budget_state_id!: number;
  readonly workshop_arrival_date!: Date;
  readonly workshop_exit_date!: Date;
  readonly budgetMechanics!: UserWorkBudget[];
  readonly observations!: string;
  readonly lax_observations!: string;
  readonly workshop_observations!: string;

  readonly last_state_change_date!: string;
  readonly workshop_purchase_id?: number;
  readonly additional_workshop_purchase_id?: number;
  readonly workshop_purchase_state_id?: number;
  readonly additional_workshop_purchase_state_id?: number;

  constructor(data: Partial<Budget>) {
    Object.assign(this, data);
  }
}
